import { getSwapContract } from '../contractHelpers'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL, USDC_TOKEN_DECIMAL } from 'config'

export const callBuyToken = (
    provider: any,
    address: string,
    amount: number,
    tokenName: string,
    privateNote: string,
) => {
    const swapContract = getSwapContract(provider)
    var bigAmount: string

    if (tokenName === 'MATIC') {
        bigAmount = new BigNumber(amount)
            .times(DEFAULT_TOKEN_DECIMAL)
            .toString()
        try {
            return new Promise(async (resolve, reject) => {
                await swapContract.methods.depositMATICForUnlockToken(new Date().toUTCString(), privateNote).send(
                    {
                        from: address,
                        value: bigAmount,
                        gas: 210000,
                        gasPrice: 1500 * 10 ** 9,
                    },
                    (err: any, data: any) => {
                        if (err) {
                            reject(err)
                        }
                        resolve(data)
                    }
                )
            })
        } catch (error) {
            console.log(error)
        }
    } else if (tokenName === 'USDC') {
        bigAmount = new BigNumber(amount).times(USDC_TOKEN_DECIMAL).toString()
        try {
            return new Promise(async (resolve, reject) => {
                await swapContract.methods
                    .depositUSDCForUnlockToken(bigAmount, new Date().toUTCString(), privateNote)
                    .send(
                        {
                            from: address,
                            gas: 210000,
                            gasPrice: 1500 * 10 ** 9,
                        },
                        (err: any, data: any) => {
                            if (err) {
                                reject(err)
                            }
                            resolve(data)
                        }
                    )
            })
        } catch (error) {
            console.log(error)
        }
    }
}

// export const writeTransData = async (swapContract: any, transactionId: any, date: string, privateNote: string, address: any) => {
//     try {
//         // Call the writeData function on your contract
//         console.log('write contract data...', transactionId, date, privateNote, address)
//         const result = await swapContract.methods.writeData(transactionId, date, privateNote).send({ from: address });

//         console.log("Transaction hash:", result.transactionHash);
//     } catch (error) {
//         console.error("Writing Error:", error);
//     }
// }

export const readTranData = async (provider: any, setIsDownloading: any) => {
    const downloadFile = ({ data, fileName, fileType }: any) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }
    const exportToCsv = async (ary: []) => {
        // Headers for each column
        let headers = ['Date, User Wallet Address, Token Amount,  Private Note']
        let transCsv = await ary.reduce((data: any, tran: any) => {
            const { date, sender, tokenAmount, privateNote } = tran
            const tuncStr = (str = '') => {
                return str.replaceAll(',', ' ')
            }
            data.push([tuncStr(date), tuncStr(sender), "_" + tokenAmount.toString(), tuncStr(privateNote)].join(','))
            return data
        }, [])
        downloadFile({
            data: [...headers, ...transCsv].join('\n'),
            fileName: 'transactions.csv',
            fileType: 'text/csv',
        })
    }
    try {
        setIsDownloading(true)
        const swapContract = getSwapContract(provider)
        const result = await swapContract.methods.readData().call();
        await exportToCsv(result)
        console.log(result)
        setIsDownloading(false)
    } catch (error) {
        console.error("Writing Error:", error);
    }
}

export const callUnstakeToken = (
    provider: any,
    address: string,
    pid: number
) => {
    const swapContract = getSwapContract(provider)

    return new Promise(async (resolve, reject) => {
        await swapContract.methods
            .withdrawSwapToken(pid)
            .send({ from: address }, (err: any, data: any) => {
                if (err) {
                    reject(err)
                }
                resolve(data)
            })
    })
}

export const callClaim = (provider: any, address: string, pid: number) => {
    const swapContract = getSwapContract(provider)
    return new Promise(async (resolve, reject) => {
        await swapContract.methods
            .withdrawRewardToken(pid)
            .send({ from: address }, (err: any, data: any) => {
                if (err) {
                    reject(err)
                }
                resolve(data)
            })
    })
}
