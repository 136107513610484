import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useWeb3Context } from 'hooks/useWeb3Context'
import { useSwapData } from 'state/swap/hooks'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import './App.css'
import Swap from 'views/Swap'
import Header from 'layouts/Header'
import Footer from 'layouts/Footer'
import styled from 'styled-components'

const App = () => {
  const web3Context = useWeb3Context()
  useSwapData(0, web3Context?.account)

  return (
    <RefreshContextProvider>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Swap /> } />
          <Route path="/swap" element={<Swap /> } />
        </Routes>
      </BrowserRouter>
      <Footer />
    </RefreshContextProvider>
  );
}

export default App;
