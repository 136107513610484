import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state/hooks'
import { useFastFresh } from 'hooks/useRefresh'
import { fetchSwapDataSync, updateApprovalStatus, updateSwapDataWithEmpty } from '../swap'
import { fetchApprovalStatus } from '../swap/fetchSwap'

export const useSwapData = (_poolNumber: number, _account: string | undefined) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()

  useEffect(() => {
    if (_account) {
      dispatch(fetchSwapDataSync({ account: _account }))
    } else {
      dispatch(updateSwapDataWithEmpty())
    }
  }, [_account, fastRefresh, dispatch])
}

export const useBalances = () => {
  const balanceMEAC = useSelector((state: any) => state.swap.balanceMEAC)
  const balanceMATIC = useSelector((state: any) => state.swap.balanceMATIC)
  const balanceUSDC = useSelector((state: any) => state.swap.balanceUSDC)

  return { balanceMEAC, balanceMATIC, balanceUSDC }
}

export const useTokenApprovalStatus = () => {
  const tokenStatus = useSelector((state: any) => state.swap.tokenStatus)
  return tokenStatus
}

export const useApprovalStatus = (_account: string | undefined, _changed: boolean) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()

  useEffect(() => {
    const fetchApprove = async (_account: string) => {
      const status = await fetchApprovalStatus(_account)
      dispatch(updateApprovalStatus({tokenStatus: status}))
    }
    if (_account) {
      fetchApprove(_account)
    }
  }, [_account, _changed, fastRefresh, dispatch])
}