import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  background: #11204D;
  padding: 15px 60px 30px 60px;
  align-items: center;
`

const StyledVertical = styled.div`
  display: grid;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 10px;
`

const StyledHorizental = styled.button`
  display: flex;
  align-items: center;
  margin: 4px 60px 4px 60px;
  @media (max-width: 768px) {
    margin: 4px 20px 4px 20px;
  }
  justify-content: space-between;
  font-size: 16px;
  background: none;
  color: #ffffff;
  border: none;
  text-align: left;
  &:hover {
    color: #ffffffaa;
  }
  &:active {
    color: #ffffff55;
  }
`

const StyledFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StyledHorizentalBold = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 60px 12px 60px;
  justify-content: space-between;
  font-size: 19px;
  font-weight: 500;
`

const StyledLogo = styled.img`
  height: 100px;
  margin-right: 50px;
`

const StyledText = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
`

const StyledImage = styled.img`
  height: 18px;
  margin-right: 15px;
`

const Footer = () => {
  return (
    <StyledFooter>
      <StyledFooterContainer>
        <StyledVertical >
          <StyledLogo src="images/Logo_WESP_10.svg" alt="logo" />
        </StyledVertical>
        <StyledVertical>
          <StyledHorizentalBold>About</StyledHorizentalBold>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/terms-of-services/");
          }}>Terms of Service</StyledHorizental>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/acceptable-use-policy/");
          }}>Acceptable Use Policy</StyledHorizental>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/privacy-policy/");
          }}>Privacy Policy</StyledHorizental>
        </StyledVertical>

        <StyledVertical>
          <StyledHorizentalBold>Company</StyledHorizentalBold>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/team/");
          }}>Team</StyledHorizental>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/whitepaper/");
          }}>Whitepaper</StyledHorizental>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/onepager");
          }}>Onepager</StyledHorizental>
        </StyledVertical>

        <StyledVertical>
          <StyledHorizentalBold>Information</StyledHorizentalBold>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://github.com/interfinetwork/project-delivery-data/blob/main/Wesp/Wesp_AuditReport_InterFi.pdf");
          }}>Audit Report</StyledHorizental>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("mailto:support@wesp.io");
          }}>Contact</StyledHorizental>

          <StyledHorizental onClick={(e) => {
            e.preventDefault();
            window.open("https://wesp.io/faq/");
          }}>FAQ</StyledHorizental>
        </StyledVertical>
        <StyledVertical>
          <StyledHorizentalBold>Community</StyledHorizentalBold>

          <StyledHorizental>
            <StyledImage src="images/social/Twitter.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://twitter.com/wesp_io")
            }} />

            <StyledImage src="images/social/Instagram.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://www.instagram.com/wesp.io/");
            }}/>

            <StyledImage src="images/social/Facebook.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://www.facebook.com/wesp.life");
            }}/>

            <StyledImage src="images/social/YouTube-01.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://www.youtube.com/@wesp_io");
            }}/>

            <StyledImage src="images/social/Telegram.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://t.me/wesp_io");
            }}/>

            <StyledImage src="images/social/Reddit-01.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://www.reddit.com/r/wesp/");
            }}/>

            <StyledImage src="images/social/Discord-01.svg" onClick={(e) => {
              e.preventDefault();
              window.open("https://discord.gg/Q6wwJ59efF");
            }}/>
          </StyledHorizental>
        </StyledVertical>
      </StyledFooterContainer>
      <StyledText>WESP 2023 The Netherlands - All Rights Reserved</StyledText>
    </StyledFooter>
  )
}

export default Footer