import { CHAIN } from 'config'
import addresses from 'config/contracts'

export const getAddress = (address: any): string => {
  const chainId = CHAIN
  return address[chainId]
}

export const getTokenAddress = () => {
  return getAddress(addresses.token)
}
export const getUsdcAddress = () => {
  return getAddress(addresses.usdc)
}
export const getSwapAddress = () => {
  return getAddress(addresses.swap)
}