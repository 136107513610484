import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { FiChevronDown } from 'react-icons/fi'
import {
  useBalances,
  useTokenApprovalStatus,
  useApprovalStatus,
} from 'state/swap/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { useWeb3Context } from 'hooks/useWeb3Context'
import { callApproveForAmount } from 'utils/calls/token'
import { callBuyToken, readTranData } from 'utils/calls/tokenSwap'
import { useLocation } from 'react-router-dom'

const StyledArea = styled.div`
    width: 100%;
    background: #555555c0;
    border-radius: 20px;
    color: #ffffff;
`
const Container = styled.div`
    padding: 30px;
`
const Title = styled.div`
    font-size: 26px;
    color: #ffffff;
    font-weight: bold;
`
const StyledInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 18px;
    border-bottom: 1px solid #ffffff50;
`
const StyledInfoReminder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 14px;
`
const StyledInfoInsufficientFudns = styled.div`
    width: 50%;
    align-items: left;
    font-size: 14px;
    color: #ff0000;
`
const StyledInfoWalletBalance = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 50%;
    align-items: right;
    font-size: 14px;
`
const StyledInputArea = styled.div`
    margin-top: 20px;
    height: 80px;
    background: #ffffff30;
    border-radius: 25px;
`
const StyledPriNoteInput = styled.input`
  background: #ffffff30;
  padding: 6px;
  padding-left: 10px;
  margin-top: 4px;
  font-size: 16px;
  outline: none;
  border: none;
  color: #fff;
  width: -webkit-fill-available;
  border-radius: 4px;
  &::placeholder {
    color: #ddd;
  }
`
const StyledInput = styled.input`
    background: transparent;
    border: none;
    margin-left: 15px;
    width: 100%;
    outline: none;
    color: #ffffff;
    font-size: 18px;

    &:focus {
        border: none;
    }
    &:active {
        border: none;
    }
`
const StyledButton = styled.button`
    width: 100%;
    min-width: 170px;
    background: #555;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 15px;
    margin-top: 10px;
    &:hover {
        color: #eee;
    }
    &:active {
        color: #fff;
    }

    &:disabled {
        color: #aaa;
        cursor: unset;
        background: #666;
    }
`
const MaxButton = styled.button`
    background: #ffffff00;
    border: none;
    color: #152c57;
    font-weight: bold;

    &:hover {
        opacity: 0.7;
    }
`
const LabelRow = styled.div`
    height: 30%;
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    padding-top: 5px;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
`
const InputRow = styled.div`
    height: 70%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-right: 10px;
`
const DropdownContainer = styled.div`
    position: relative;
    display: inline-block;
`
const DropdownButton = styled.button`
    background-color: transparent; /* Green */
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`
const DropdownContent = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #f1f1f1;
    min-width: 160px;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
`
const DropdownItem = styled.a`
    display: flex;
    color: black;
    padding: 12px 16px;
    text-decoration: none;

    &:hover {
        background-color: #ddd;
    }
`
const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledImage = styled.img`
  width: 30px;
  margin-right: 5px;
`
const DownloadButton = styled.button`
  background:#ddd; 
  border:none; 
  border-radius: 4px; 
  cursor:pointer;
  &:hover {
    background-color: #ccc;
  }
`
const Info = (props) => {
  const { title, value } = props

  return (
    <StyledInfo>
      <div>{title}</div>
      <div>{value}</div>
    </StyledInfo>
  )
}

const BakedCard = (props) => {
  const web3Context = useWeb3Context()
  const { account, connectWallet, provider } = web3Context
  const regInput = React.useRef()
  const [approveProgress, setApproveProgress] = useState(false)
  const [buyProgress, setBuyProgress] = useState(false)
  const [privateNote, setPrivateNote] = useState('')
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloadVisible, setIsDownloadVisible] = useState(false)
  const location = useLocation();
  useApprovalStatus(account, approveProgress)

  const approvalStatusBigNumber = useTokenApprovalStatus()
  const approvalStatus = getFullDisplayBalance(
    new BigNumber(approvalStatusBigNumber),
    6
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) setPrivateNote(code)
  }, [location.search])
  const balance = useBalances()

  const [inputValue, setInputValue] = useState(false)

  useEffect(() => {
    setIsDownloadVisible(process.env.REACT_APP_ADDRESS === account)
  }, [account])

  useEffect(() => {
    if (approvalStatus) {
      setApproveProgress(false)
    }
  }, [approvalStatus])

  const handleBuywithMatic = async () => {
    if (!account) {
      connectWallet()
      return
    }
    let amount = regInput.current?.value

    if (amount < 2.5) {
      alert('Reminder: Minimum amount for Deposit is 2.5.')
      return
    }
    try {
      if (provider && account) {
        setBuyProgress(true)
        await callBuyToken(
          provider,
          account,
          amount,
          selectedToken,
          privateNote,
        )
        setBuyProgress(false)
      }
    } catch (error) {
      setBuyProgress(false)
      console.log(error)
    }
  }

  const refreshBuyStats = () => {
    const compareAmount = parseFloat(
      selectedToken === 'MATIC'
        ? getFullDisplayBalance(new BigNumber(balance.balanceMATIC))
        : getFullDisplayBalance(new BigNumber(balance.balanceUSDC), 6)
    )
    if (regInput.current.value > compareAmount) {
      document.getElementById('insuFunds').style.display = 'block'
      document.getElementById('insuFundsSpace').style.display = 'none'
    } else {
      document.getElementById('insuFunds').style.display = 'none'
      document.getElementById('insuFundsSpace').style.display = 'block'
    }

    if (document.getElementById('buyBtn') && account) {
      if (
        regInput.current.value < 2.5 ||
        regInput.current.value > compareAmount
      )
        document.getElementById('buyBtn').disabled = true
      else document.getElementById('buyBtn').disabled = false
    }

    if (document.getElementById('aprBtn') && selectedToken === 'USDC' && account) {
      if (
        regInput.current.value < 2.5 ||
        regInput.current.value > compareAmount
      )
        document.getElementById('aprBtn').disabled = true
      else document.getElementById('aprBtn').disabled = false
    }
  }

  const onMax = () => {
    let maxValue = 0
    if (selectedToken === 'MATIC')
      maxValue =
        getFullDisplayBalance(new BigNumber(balance.balanceMATIC)) - 0.4
    else
      maxValue = getFullDisplayBalance(
        new BigNumber(balance.balanceUSDC),
        6
      )

    if (maxValue > 0) setInputValue(maxValue)

    refreshBuyStats()
  }

  const onInputChange = () => {
    setInputValue(regInput.current.value)

    refreshBuyStats()
  }

  const handleBuyWithUsdc = async () => {
    if (!account) {
      connectWallet()
      return
    }
    setApproveProgress(true)

    const amount = regInput.current.value
    try {
      if (provider && account) {
        await callApproveForAmount(
          provider,
          account,
          amount,
          selectedToken,
        )
        setApproveProgress(false)
        setBuyProgress(true)
        await new Promise((resolve) => setTimeout(resolve, 4000))

        await callBuyToken(
          provider,
          account,
          amount,
          selectedToken,
          privateNote,
        )
        setBuyProgress(false)
      }
    } catch {
      setApproveProgress(false)
      setBuyProgress(false)
    }
  }

  const options = ['MATIC', 'USDC']

  const [isOpen, setIsOpen] = useState(false)
  const [selectedToken, setSelected] = useState(options[0])
  const toggleDropdown = () => setIsOpen(!isOpen)

  useEffect(() => {
    setInputValue(0)
    refreshBuyStats()
  }, [selectedToken, account])

  const handleOptionClick = (option) => {
    setSelected(option)
    setIsOpen(false)
    setInputValue(0)
    refreshBuyStats()
  }

  return (
    <StyledArea>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title>Swap WESP</Title>
          {isDownloadVisible &&
            <DownloadButton onClick={() => readTranData(provider, setIsDownloading)}>{isDownloading ? 'Loading...' : 'Download'}</DownloadButton>
          }
        </div>
        <div>
          <Info title='WESP Price: ' value='0.12 USD' />
          <Info
            title='WESP Balance: '
            value={`${getFullDisplayBalance(
              new BigNumber(balance.balanceMEAC)
            )}`}
          />
          <StyledInputArea>
            <LabelRow>
              <StyledInfoInsufficientFudns id='insuFunds'>
                Insufficient Funds!
              </StyledInfoInsufficientFudns>
              <StyledInfoInsufficientFudns id='insuFundsSpace'>
                &nbsp;
              </StyledInfoInsufficientFudns>
              <StyledInfoWalletBalance>
                <div>{`Balance: `}&nbsp;</div>
                <div>{`${selectedToken === 'MATIC'
                  ? getFullDisplayBalance(
                    new BigNumber(
                      balance.balanceMATIC
                    )
                  )
                  : getFullDisplayBalance(
                    new BigNumber(
                      balance.balanceUSDC
                    ),
                    6
                  )
                  }`}</div>
              </StyledInfoWalletBalance>
            </LabelRow>
            <InputRow>
              <StyledInput
                ref={regInput}
                type='number'
                min={0.0}
                value={inputValue}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={onInputChange}
                placeholder='0.0'
              />
              <MaxButton
                onClick={onMax}
                scale='sm'
                variant='text'
              >
                MAX
              </MaxButton>
              <DropdownContainer>
                <DropdownButton onClick={toggleDropdown}>
                  <StyledFlex>
                    <StyledImage
                      src={
                        selectedToken === 'MATIC'
                          ? 'images/polygon-matic-logo.svg'
                          : 'images/usd-coin-usdc-logo.svg'
                      }
                    />
                    <div>{selectedToken}</div>
                  </StyledFlex>
                  <FiChevronDown />
                </DropdownButton>
                {isOpen && (
                  <DropdownContent>
                    {options.map((option) => (
                      <DropdownItem
                        key={option}
                        onClick={() =>
                          handleOptionClick(option)
                        }
                      >
                        <StyledFlex>
                          <StyledImage
                            src={
                              option === 'MATIC'
                                ? 'images/polygon-matic-logo.svg'
                                : 'images/usd-coin-usdc-logo.svg'
                            }
                          />
                          <div>{option}</div>
                        </StyledFlex>
                      </DropdownItem>
                    ))}
                  </DropdownContent>
                )}
              </DropdownContainer>
            </InputRow>
          </StyledInputArea>
        </div>
        <StyledInfoReminder>
          Minimum Deposit amount $2.5
        </StyledInfoReminder>
        <div>
          <StyledPriNoteInput maxLength={200} type='text' value={privateNote} onChange={(e) => setPrivateNote(e.target.value)} placeholder='Code (additional)' />
        </div>
        {selectedToken === 'USDC' ? (
          //&&
          // approvalStatus < regInput.current.value
          <StyledButton id='aprBtn' onClick={handleBuyWithUsdc}>
            {approveProgress
              ? 'Approving...'
              : buyProgress
                ? 'Buying...'
                : 'Buy'}
          </StyledButton>
        ) : (
          <StyledButton id='buyBtn' onClick={handleBuywithMatic}>
            {buyProgress ? 'Buying...' : 'Buy'}
          </StyledButton>
        )}
      </Container>
    </StyledArea>
  )
}

export default BakedCard
