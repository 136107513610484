import styled from 'styled-components'
import StakeCard from './components/StakeCard'

const StyledSwapArea = styled.div`
  max-width: 1500px;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 180px);
  min-height: 550px;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const CardArea = styled.div`
  width: 100%;
  max-width: 520px;
`

const StyledSpace = styled.div`
  margin-top: 100px;
`

const Swap = () => {
  return (
    <StyledSwapArea>
      <StyledSpace></StyledSpace>
      <Flex>
        <CardArea>
          <StakeCard/>
        </CardArea>
      </Flex>
      <StyledSpace></StyledSpace>
    </StyledSwapArea>
  )
}

export default Swap