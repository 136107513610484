import React from 'react'
import styled from 'styled-components'
import { useWeb3Context } from 'hooks/useWeb3Context'

const StyledConnectButton = styled.button`
  width: 160px;
  height: 35px;
  padding: 5px 5px;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
  background: #F76C00;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover {
    opacity: 0.9;
  }
`

const WalletConnectButton = () => {
  const web3Context = useWeb3Context()
  const displayAddress =  `${web3Context?.account.substring(0, 6)}...${web3Context?.account.substring(web3Context?.account.length - 6)}`
  
  return (
    <>
      { web3Context?.account ?
        <StyledConnectButton onClick={web3Context?.disconnect}>
          {displayAddress}
        </StyledConnectButton>
        :
        <StyledConnectButton onClick={web3Context?.connectWallet}>Connect</StyledConnectButton>
      }
    </>
  )
}

export default WalletConnectButton