import React from 'react'
import styled from 'styled-components'
import WalletConnectButton from 'components/WalletConnectButton'

const StyledHeader = styled.div`
    background: white;
    padding: 30px 50px 20px 30px;
    justify-content: space-between;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    box-shadow: 10px 4px 15px rgba(0,0,0,0.2);
`

const StyledButton = styled.button`
    width: 160px;
    height: 35px;
    background: transparent;
    border: none;
    color: #dd732c;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid transparent;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    &:hover {
        opacity: 0.8;
    }
    // &:active {
    //     background: #6a1db4;
    // }
`

const StyledButtonSignIn = styled.button`
    width: 160px;
    height: 35px;
    color: #555;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 2px solid #DD732C;
    background: transparent;
    &:hover {
        opacity: 0.8;
    }
`

const StyledIconMeta = styled.img`
    height: 1rem;
    margin-right: 10px;
`

const StyledText = styled.div`
    text-align: center;
`

const StyledLogo = styled.img`
    height: 35px;
`

const Header = () => {
    const addPolygon = async () => {
        const chainId = 137
        const chainIdHex = '0x' + chainId.toString(16)
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainIdHex }],
        })
    }

    const addMEAC = async () => {
        await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: '0x5783597bA1a5E5F5182dB58DbCe47657A9535eEc',
                    symbol: 'WESP',
                    decimals: '18',
                    image: '',
                },
            },
        })
    }

    return (
        <StyledHeader>
            <StyledLogo
                src='images/Logo.svg'
                alt='logo'
                onClick={(e) => {
                    e.preventDefault()
                    window.open('https://wesp.io')
                }}
            />
            {/* <StyledButton
                onClick={(e) => {
                    e.preventDefault()
                    window.open(
                        'https://acc.wesp.io/nft'
                    )
                }}
            >
                <StyledText>NFTs</StyledText>
            </StyledButton> */}
            <StyledButton onClick={() => addPolygon()}>
                <StyledIconMeta src='images/MetaMask_Fox.svg' alt='MetaMask' />
                <StyledText>Add Polygon</StyledText>
            </StyledButton>
            <StyledButton onClick={() => addMEAC()}>
                <StyledIconMeta src='images/MetaMask_Fox.svg' alt='MetaMask' />
                <StyledText>Add WESP</StyledText>
            </StyledButton>
            <StyledButton
                onClick={(e) => {
                    e.preventDefault()
                    window.open('http://staking.wesp.io')
                }}
            >
                <StyledText>Staking</StyledText>
            </StyledButton>
            <StyledButtonSignIn
                onClick={(e) => {
                    e.preventDefault()
                    window.open('https://wesp.io/whitelist')
                }}
            >
                Whitelist
            </StyledButtonSignIn>
            <WalletConnectButton></WalletConnectButton>
        </StyledHeader>
    )
}

export default Header
