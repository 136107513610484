const contracts = {
    token: {
        // MEAC(token for Metacoms)
        137: '0x5783597bA1a5E5F5182dB58DbCe47657A9535eEc',
    },
    swap: {
        // 137: '0x30a73d5De4E713ef769509D378371F1C510d6438',
        137: '0x55839b2c1ECe09187C53cB7cD360C36FBF25f358',
    },
    usdc: {
        137: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    },
}

export default contracts
