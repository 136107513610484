import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'state'
import { fetchBalanceMEAC, fetchBalanceMATIC, fetchBalanceUSDC } from '../swap/fetchSwap'

interface SwapDataResponse {
  balanceMEAC: any,
  balanceMATIC: any,
  balanceUSDC: any,
}

const initialState = {
  balanceMEAC: '0',
  balanceMATIC: '0',
  balanceUSDC: '0',
  
  tokenStatus: false,
  status: 'idle',
};

export const fetchSwapDataSync = createAsyncThunk<SwapDataResponse, {account: string}, {state: RootState}>(
  'swap/fetchSwapData',
  async ({ account }) => {
    const balanceMEAC = await fetchBalanceMEAC(account);
    const balanceMATIC = await fetchBalanceMATIC(account);
    const balanceUSDC = await fetchBalanceUSDC(account);
    
    return {
      balanceMEAC,
      balanceMATIC,
      balanceUSDC
    }
  }
)

export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    updateApprovalStatus: (state, action) => {
      state.tokenStatus = action.payload.tokenStatus
    },
    updateSwapDataWithEmpty: (state) => {
      state.status = 'loading'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSwapDataSync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSwapDataSync.fulfilled, (state, action) => {
        state.balanceMEAC = action.payload.balanceMEAC
        state.balanceMATIC = action.payload.balanceMATIC
        state.balanceUSDC = action.payload.balanceUSDC
        state.status = 'idle'
      })
      .addCase(fetchSwapDataSync.rejected, (state) => {
        state.status = 'failed'
      })
  }
});

export const { updateApprovalStatus, updateSwapDataWithEmpty } = swapSlice.actions

export default swapSlice.reducer;
