import BigNumber from 'bignumber.js'
import ERC20ABI from 'config/abis/erc20.json'
import { getTokenAddress, getUsdcAddress, getSwapAddress } from 'utils/addressHelpers'
import { getContract } from 'utils/contractHelpers'
import { simpleRpcProvider } from 'utils/providers'

export const fetchBalanceMEAC = async (account: string) => {
  const meacContract = getContract(ERC20ABI, getTokenAddress());
  const meacBalances = await meacContract.balanceOf(account);
  const parsedMEACBalances = new BigNumber(meacBalances._hex).toNumber();
  return parsedMEACBalances
}

export const fetchBalanceMATIC = async (account: string) => {
  const maticBalances = await simpleRpcProvider.getBalance(account)
  const parsedMATICBalances = new BigNumber(maticBalances._hex).toNumber();
  return parsedMATICBalances
}

export const fetchBalanceUSDC = async (account: string) => {
  const usdcContract = getContract(ERC20ABI, getUsdcAddress());
  const usdcBalances = await usdcContract.balanceOf(account);
  const parsedUSDCBalances = new BigNumber(usdcBalances._hex).toNumber();
  return parsedUSDCBalances
}

export const fetchApprovalStatus = async (account: string) => {
  const tokenAddress = getUsdcAddress();
  const swapAddress = getSwapAddress();
  const tokenContract = getContract(ERC20ABI, tokenAddress);
  const approvalStatus = await tokenContract.allowance(account, swapAddress);
  const parsedApprovalStatus = new BigNumber(approvalStatus._hex).toNumber();
  return parsedApprovalStatus
}
