import { getUsdcContract } from '../contractHelpers'
import { getSwapAddress } from '../addressHelpers'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL, USDC_TOKEN_DECIMAL } from 'config'

export const callApproveForAmount = (provider: any, address: string, amount: number) => {
  const tokenContract = getUsdcContract(provider)
  const swapAddress = getSwapAddress()
  const bigAmount = new BigNumber(amount).times(USDC_TOKEN_DECIMAL).toString();

  return new Promise(async(resolve, reject) => {
    await tokenContract.methods
      .approve(swapAddress, bigAmount)
      .send({ from: address, gas: 210000, gasPrice: 1500 * 10 ** 9 }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }
        resolve(data)
      })
  })
}

